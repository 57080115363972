.background {
    background: var(--background-2);
}

.wrapper {
    min-height: 50vh;
    padding: 2rem 0 2rem 0;

    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
}

.wrapper img {
    width: 100%;
    max-width: 400px;
}

.about-text {
    font-size: clamp(35px, 8vw, 20px);
}

.about-text h1 {
    margin: 0;
}

@media (max-width: 600px) {
    .wrapper {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
