:root {
    --background: #212121;
    --background-2: #303030;
    --text: #fff;
    --text-hover: #c9c9c9;
    --text-sub: #9b9b9b;
    --primary: #1e88e5;
    --yellow: #f9a825;

    --border-radius-md: 14px;
    --border-radius-lg: 20px;
    --border-radius-xl: 30px;

    --shadow: 0px 0px 24px rgb(0, 0, 0, 0.2);
    --transition: 150ms ease-in-out;

    --background-rgb: 33, 33, 33;
    --primary-rgb: 30, 136, 229;
}

@font-face {
    font-family: QType;
    src: url(../fonts/QType.ttf);
}

@font-face {
    font-family: Amulya;
    src: url(../fonts/Amulya-Bold.ttf);
}

@font-face {
    font-family: Synonym;
    src: url(../fonts/Synonym-Regular.ttf);
}

::selection {
    background-color: rgb(var(--primary-rgb), 0.3);
}

* {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    background: var(--background);
    margin: 0;

    color: var(--text);
    font-family: Synonym;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Amulya;
    font-weight: normal;
}

a {
    color: inherit;
    text-decoration: underline 1px rgb(var(--primary-rgb), 0.4);

    transition: var(--transition);
}

a:hover {
    text-decoration-color: var(--primary);
}

a[type="button"],
button {
    background: rgb(var(--primary-rgb), 0.6);
    color: inherit;

    font-family: Amulya;
    text-align: center;
    text-decoration: none;

    padding: 0.5rem 1rem 0.5rem 1rem;
    margin: 0.2rem;

    border: none;
    border-radius: var(--border-radius-md);

    transition: var(--transition);
}

a[type="button"]:hover,
button:hover {
    cursor: pointer;
    background: rgb(var(--primary-rgb), 0.4);
    color: var(--primary);
}

img {
    pointer-events: none;
}

.mobile-background {
    z-index: 1001;
    display: none;

    background: rgb(var(--background-rgb), 0.5);
    backdrop-filter: blur(1px);

    height: 100%;
    width: 100%;
    position: fixed;
}

nav {
    z-index: 1000;

    background: var(--background-2);
    box-shadow: var(--shadow);

    height: 60px;
    width: 100%;
    position: fixed;
    top: 0;

    padding-left: 10%;
    padding-right: 10%;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

nav > .brand {
    font-family: QType;

    height: max-content;

    display: flex;
    gap: 0.2rem;
    align-items: center;
}

nav > .brand > a {
    text-decoration: none;
}

header .content.desktop ul {
    display: flex;
    gap: 1rem;
}

header .content ul > li {
    list-style: none;
}

header .content a {
    text-decoration: none;
    font-weight: bold;

    transition: var(--transition);
}

header .content ul > li > a:hover {
    color: var(--text-hover);
}

header button.menu-toggle {
    display: none;

    background: none;
    font-size: 1.5rem;

    padding: 0;
    margin: 0;
}

header .content.mobile {
    position: fixed;
    transform: translateX(100%);
    transition: var(--transition);
}

@media (max-width: 600px) {
    nav > .content.desktop {
        display: none;
    }

    header button.menu-toggle {
        display: block;

        position: absolute;
        top: 0;
        right: 0;

        margin: 1rem 2rem 0 0;
    }

    header .mobile {
        z-index: 1002;

        background: var(--background-2);
        box-shadow: var(--shadow);

        height: 100%;
        width: 70%;

        position: fixed;
        right: 0;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
    }

    header[data-menu="true"] .mobile {
        transform: translateX(0%);
    }
}

.container {
    max-width: 1100px;
    padding: 0 1rem 0 1rem;
    margin: 0 auto;
}

.spacer {
    height: 20px;
}

footer {
    background: var(--background);
    padding: 2rem 1rem 2rem 1rem !important;

    display: flex;
    flex-direction: column;
    align-items: center;
}
