.wrapper {
    min-height: 90vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
}

.wrapper img {
    width: 100%;
    max-width: 400px;
}

.hero-text {
    font-size: clamp(40px, 8vw, 80px);
}

.hero-text h1 {
    margin: 0;
    font-family: QType;
}

.hero-subtext {
    margin: 0;
    margin-top: -1rem;
    margin-left: 5px;
    font-family: Amulya;
    color: var(--text-sub);
}

@media (max-width: 600px) {
    .wrapper {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
