.background {
    background: var(--background-2);
}

.wrapper {
    min-height: 50vh;
    padding: 2rem 0 2rem 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.wrapper img {
    width: 100%;
    max-width: 400px;
}

.join-text {
    font-size: clamp(40px, 8vw, 50px);
    font-family: Amulya;
    text-transform: uppercase;
}

.join-text h1 {
    margin: 0;
}
