.background {
    background: var(--background);
}

.wrapper {
    min-height: 50vh;
    padding: 2rem 0 2rem 0;
}

.wrapper img {
    width: 100%;
    max-width: 400px;
}

.feature {
    min-height: max-content;
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
}

.feature-text {
    font-size: clamp(35px, 8vw, 20px);
}

.feature-text h1 {
    margin: 0;
}

@media (max-width: 600px) {
    .feature {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
    }

    .feature:nth-child(2) {
        flex-direction: column;
    }
}
